<template>
  <div class="home">
    <div class="top">
      <img
        class="background"
        src="../assets/img/head-about-us.png"
      />
      <div class="content">
        <div class="title">
          {{title}}
        </div>
        <div class="txt">
          {{content}}
        </div>

        <div class="start">
          {{button}}
        </div>
      </div>
    </div>

    <div class="detail">
      <div class="acquire">
        <div class="row">
          <div class="left">
            <div class="title">{{details[0].title}}</div>
            <div class="txt">{{details[0].txt}}</div>
          </div>

          <div class="right">
            <img src="@img/acquire-right.png" />
          </div>
        </div>
      </div>
      <div class="roi">
        <div class="row">
          <div class="left">
            <img src="@img/roi-left.png" />
          </div>
          <div class="right">
            <div class="title">{{details[1].title}}</div>
            <div class="txt">{{details[1].txt}}</div>
          </div>
        </div>
      </div>
      <div class="analytics">
        <div class="row">
          <div class="left">
            <div class="title">{{details[2].title}}</div>
            <div class="txt">{{details[2].txt}}</div>
          </div>
          <div class="right">
            <img src="@img/analytics-right.png" />
          </div>
        </div>
      </div>
      <div class="major">

      </div>
    </div>
    <div class="bottom">
      <img src="@img/head-about-us.png" />
      <div class="desc">
        <div class="title">{{bottom.title}}</div>
        <div class="title">{{bottom.title2}}</div>
        <div class="txt">{{bottom.txt}}</div>
        <div class="progress"></div>
        <div class="start">
          {{button}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      title: "Acquire, Get Discovered, Reach and Retain",
      content: `Our highest priority is to privide you with the most efficient tools and guidance, so you can focus on expanding your user base rapidly while creating new award winning apps. And us to your journey and grow`,
      button: "Let's Start",
      details: [
        {
          title: 'Acquire High Impact Users Globally',
          txt: 'With Ad More Media advertising platform you gain access to new global users. Our system helps you to get optimal results for your campaigns with efficient tools and support team which will guide you along your growth journey',
        },
        {
          title: 'Gain More, Pay Less.',
          txt: 'Gain more. Pay less. Our proprietary optimization system will help you to pay the best value for bid in order to be an ROI positive in your UA campaigns.'
        },
        {
          title: 'Real Time Analytics',
          txt: 'Be on top of your campaigns with Ad More Media real-time analytics platform. Monitor your KPIs, take decisions based on up to date data and scale your user base.',
        }, {
          title: 'Acquire High Impact Users Globally',
          txt: 'With Ad More Media advertising platform you gain access to new global users. Our system helps you to get optimal results for your campaigns with efficient tools and support team which will guide you along your growth journey',
        }
      ],
      bottom: {
        title: "All Major Ad Formats",
        title2: "Powered by Creative",
        txt: "Our dynamic HTML creative formats programmatically customize the right mix of ad units to build the right monetization strategy for each user. Our comprehensive portfolio of high-impact ad creatives convert better than industry benchmarks.",
      }
    }
  },
  components: {

  }
}
</script>

<style>
.home > .top {
  width: 100%;
  height: 600px;
  /* position: relative; */
}

.top > .content {
  top: 160px;
  width: 55%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content > .title {
  font-size: 64px;
}

.content > .txt {
  text-align: left;
  margin-top: 30px;
  font-size: 22px;
  line-height: 36px;
}

.content > .start {
  margin-top: 40px;
  width: 225px;
  height: 75px;
  background-color: rgb(214, 27, 128);
  border-radius: 8px;
  font-size: 32px;
  font-weight: bold;
  line-height: 75px;
}

.detail {
  margin-top: 80px;
}

.detail .row {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row .title {
  font-size: 40px;
  margin-bottom: 40px;
}

.row .txt {
  font-size: 24px;
  line-height: 30px;
  font-weight: normal;
}

.acquire {
  width: 100%;
  background-color: white;
  color: rgb(88, 88, 88);
  margin-bottom: 40px;
}

.acquire .left {
  width: 40%;
}

.acquire .right {
  width: 640px;
  height: 420px;
}

.roi {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: rgb(74, 74, 210);
  color: white;
}

.roi .right {
  padding-right: 40px;
  width: 45%;
}

.analytics {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: white;
  color: rgb(88, 88, 88);
}

.analytics .left {
  width: 45%;
  margin-right: 40px;
}

.bottom {
  position: relative;
  color: white;
  /* background-color: tomato; */
}

img {
  vertical-align: bottom;
  /* 因为img默认是按基线(baseline)对齐的，会产生白色的底边 */
}

.bottom > .desc {
  position: absolute;
  top: 60px;
  left: 60%;

  width: 450px;
}

.bottom .title {
  font-size: 40px;
}

.bottom .txt {
  font-size: 22px;
  line-height: 32px;
  font-weight: normal;
  margin-top: 20px;
}

.bottom .start {
  margin-top: 40px;
  width: 225px;
  height: 75px;
  background-color: rgb(214, 27, 128);
  border-radius: 8px;
  font-size: 32px;
  font-weight: bold;
  line-height: 75px;
  text-align: center;
  color: white;
}
</style>